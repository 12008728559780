<div id="organizationNew" *ngIf="authorized()">
    <ol class="breadcrumb">
        <li><a [routerLink]="['']" >{{'views.organization.Home' | translate }}</a></li>
        <li><a (click)="gotoOrganizationListPage()" >{{'views.organization.Organization list' | translate }}</a></li>
        <li class="active" *ngIf="organization">{{organization.name}}</li>
    </ol>    
    <span class="logout" ><a href="./oidc/logout">{{'views.home.Log out' | translate }}</a></span>

    <div class="titleicon"><img src="assets/images/homepage/organizations.png" alt="organization management"/></div>
       <h1 *ngIf="organization">{{organization.name}}</h1>
       <div *ngFor = "let alert of alerts">
        <p class="alert " [ngClass]="{'alert-danger' : alert.type=='danger','alert-info' : alert.type=='info'}">
            {{alert.msg}}
            <i  (click)="closeAlert(i)" aria-hidden="true" class="fa fa-times" style="
            float:right;
   font-size: 22px; "></i>
        </p>
    </div>
      <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="panel-body">
            <form [formGroup]="organizationDetailRequest" (ngSubmit)="onSubmit(organizationDetailRequest)" class="form-horizontal" role="form">
                <div class="form-group" show-errors>
                    <label for="organization.status" class="col-sm-2 control-label">{{'views.user.Status'| translate}}</label>
                    <div class="col-sm-7">
                        <img style="width: 25px" *ngIf="organization?.status === 'suspended'"
                        src="assets/images/icons/bullet_red.png" alt="{{'views.user.suspended' | translate}}"
                        title="{{'views.user.suspended' | translate}}" />
                    <img style="width: 25px" *ngIf="organization?.status === 'active'"
                        src="assets/images/icons/bullet_green.png" alt="{{'views.user.active' | translate}}"
                        title="{{'views.user.active' | translate}}" />
                    </div>
                </div>
                <div class="form-group" show-errors>
                    <label for="organization.name" class="col-sm-2 control-label ">{{'views.organization.Name' | translate }}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" [(ngModel)]="organization.name" id="organization.name" name="name" formControlName="name" required />
                    </div>
                    <div class="col-sm-3" *ngIf="organizationDetailRequest.controls.name.errors!=null">
                        <p class="error-block" *ngIf="organizationDetailRequest.controls.name.errors.required && organizationDetailRequest.controls.name.touched">{{'views.public.otpmail.Required'| translate}}</p>
                        <p class="error-block" *ngIf="!(organizationDetailRequest.controls.name.errors.required) && organizationDetailRequest.controls.name.status == 'INVALID' &&organizationDetailRequest.controls.name.touched">{{'views.service.Invalid Input' | translate}}</p>
                    </div>
                </div>
                <div class="form-group" show-errors>
                    <label for="organization.technicalId" class="col-sm-2 control-label ">{{'views.organization.Technical ID' | translate }}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control"  [(ngModel)]="organization.technicalId" id="organization.technicalId" name="technicalId" 
                        formControlName="technicalId"  disabled/>
                    </div>
                </div>
                <div class="form-group" show-errors>
                    <label for="organization.description" class="col-sm-2 control-label ">{{'views.organization.Description' | translate }}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="organization.description" [(ngModel)]="organization.description" name="description" formControlName="description"/>
                    </div>
                </div>
                <div class="form-group" *ngIf="authorizedActivationParam()" show-errors>
                    <label for="organization.emailLinkValidityDurationHours" class="col-sm-2 control-label ">{{'views.organization.Email activation link validity duration (hours)' | translate }}</label><!-- TODO Mathijs, can we make this column wider?-->
                    <div class="col-sm-7">
                        <input type="text"  class="form-control" [(ngModel)]="organization.emailLinkValidityDurationHours"  id="organization.emailLinkValidityDurationHours"  (keydown)="numericOnly($event)"  name="emailLinkValidityDurationHours" formControlName="emailLinkValidityDurationHours"/>
                    </div>
                    <div class="col-sm-3">
                        <p class="error-block" *ngIf="organizationDetailRequest.controls['emailLinkValidityDurationHours'].status == 'INVALID' && organizationDetailRequest.controls.emailLinkValidityDurationHours.touched">{{'views.organization.Please enter a value equal or greater than 1.'| translate }}</p>
                    </div>
                </div>
                <div class="form-group" show-errors>
                  <label for="organization.tenantName" class="col-sm-2 control-label ">{{'views.organization.Tenant Name' | translate }}</label>
                  <div class="col-sm-7">
                      <input type="text" class="form-control" id="organization.tenantName" name="tenantName" 
                      formControlName="tenantName"  [attr.disabled]="true"/>
                  </div>
              </div>
                <div class="form-group" show-errors>
                    <label  for="organization.contactEmail" class="col-sm-2 control-label ">
                    {{'views.organization.Contact email'| translate}}</label>
                    <div class="col-sm-7">
                        <span class="input-group">
                            <span class="input-group-addon">@</span>
                            <input type="text" class="form-control" 
                                   name="contactEmail" id="organization.contactEmail" 
                                   [(ngModel)]="organization.contactEmail"
                                   formControlName="contactEmail"
                            />
                        </span>
                    </div>
                    <div class="col-sm-3">
                        <p class="error-block" *ngIf="organizationDetailRequest.controls['contactEmail'].hasError('pattern') && organizationDetailRequest.controls.contactEmail.touched">{{'views.organization.The email address is invalid.'| translate}}</p>
                    </div>
                </div>
                <div class="form-group" *ngIf="authorizedActivationParam()">
                    <div class="col-sm-offset-2 col-sm-10">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" id="sendActivationEmailToRequester" name="requestable" formControlName="sendActivationEmailToRequester"/>
                                <span class="">
                                {{'views.organization.Send the activation e-mail to the requester.'| translate }}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="authorizedEmailOtpParam()">
                  <div class="col-sm-offset-2 col-sm-10">
                      <div class="checkbox">
                          <label>
                              <input type="checkbox" [(ngModel)]="organization.emailOtp" id="organization.emailOtp" name="requestable" formControlName="emailOtp"/>
                              <span class="">
                              {{"views.public.pwdset.ApprovedEmailOTP" | translate }}</span>
                          </label>
                      </div>
                  </div>
              </div>
              <div class="form-group" *ngIf="authorized()">
                  <div class="col-sm-offset-2 col-sm-10">
                      <div class="checkbox">
                          <label>
                              <input type="checkbox" [(ngModel)]="organization.featureflag1" id="organization.featureflag1" name="requestable" formControlName="featureflag1"/>
                              <span class="">
                              {{"views.home.view organizationsDetails.Featureflag1" | translate }}</span>
                          </label>
                      </div>
                  </div>
              </div>
              <div class="form-group" *ngIf="authorized()">
                  <div class="col-sm-offset-2 col-sm-10">
                      <div class="checkbox">
                          <label>
                              <input type="checkbox" [(ngModel)]="organization.featureflag2" id="organization.featureflag2" name="requestable" formControlName="featureflag2"/>
                              <span class="">
                              {{"views.home.view organizationsDetails.Featureflag2" | translate }}</span>
                          </label>
                      </div>
                  </div>
              </div>
              <div class="form-group" *ngIf="authorized()">
                  <div class="col-sm-offset-2 col-sm-10">
                      <div class="checkbox">
                          <label>
                              <input type="checkbox" [(ngModel)]="organization.featureflag3" id="organization.featureflag3" name="requestable" formControlName="featureflag3"/>
                              <span class="">
                              {{"views.home.view organizationsDetails.Featureflag3" | translate }}</span>
                          </label>
                      </div>
                  </div>
              </div>
              <div class="form-group" *ngIf="authorized()">
                  <div class="col-sm-offset-2 col-sm-10">
                      <div class="checkbox">
                          <label>
                              <input type="checkbox" [(ngModel)]="organization.featureflag4" id="organization.featureflag4" name="requestable" formControlName="featureflag4"/>
                              <span class="">
                              {{"views.home.view organizationsDetails.Featureflag4" | translate }}</span>
                          </label>
                      </div>
                  </div>
              </div>
              <div class="form-group" *ngIf="authorized()">
                  <div class="col-sm-offset-2 col-sm-10">
                      <div class="checkbox">
                          <label>
                              <input type="checkbox" [(ngModel)]="organization.featureflag5" id="organization.featureflag5" name="requestable" formControlName="featureflag5"/>
                              <span class="">
                              {{"views.home.view organizationsDetails.Featureflag5" | translate }}</span>
                          </label>
                      </div>
                  </div>
              </div>
              <div class="form-group" *ngIf="authorized()">
                  <div class="col-sm-offset-2 col-sm-10">
                      <div class="checkbox">
                          <label>
                              <input type="checkbox" [(ngModel)]="organization.featureflag6" id="organization.featureflag6" name="requestable" formControlName="featureflag6"/>
                              <span class="">
                              {{"views.home.view organizationsDetails.Featureflag6" | translate }}</span>
                          </label>
                      </div>
                  </div>
              </div>
                <div class="col-sm-2"></div>
                <div class="col-sm-7">
                    <div class="btn-group savebutton">
                        <button type="button"
                                class="btn btn-default"
                                (click)="submit()"
                                [attr.disabled]="organizationDetailRequest.status === 'INVALID'? '':null">
                            <span class="glyphicon glyphicon-floppy-disk" style="margin-right: 10px"></span>{{'views.organization.Save' | translate }}</button>
                    </div>
                </div>
            </form>
            </div>
            </div>
            <accordion>
              <!-- CONTRACT Module section
                <div id="organizations" *ngIf="authorizedContracts()" >
                    <accordion-group is-open="false" is-disabled="false">
                        <div accordion-heading  style="width: 100%;">  
                            <div style="width: 50px; float: left">                    
                            <img src="assets/images/homepage/organizations.png" alt="{{'views.user.Organization membership' | translate}}" width="30px"/>
                           </div> {{'views.organization.Contract subscriptions' | translate }} ({{contractcount}})
                        </div>
                   
                    <span class="" *ngIf="contractcount < 1">
                        {{'views.organization.The organization is not linked to any contract.' | translate }} <br />
                        <br />
                    </span>
                    <div class="example-table-container">
                  
                        <table mat-table [dataSource]="contractfilteredtabledata" class="example-table-container"  matSort matSortActive="name"
                        matSortDisableClear matSortDirection="asc" (matSortChange)="resetPaging()" style="width: 100%;">
                        
                    
                          <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef>{{'views.user.Status'|translate}}</th>
                            <td mat-cell *matCellDef="let row">
                              <img style="width: 25px" *ngIf="row.status === 'suspended'"
                              src="assets/images/icons/bullet_red.png" alt="{{'views.user.suspended' | translate}}"
                              title="{{'views.user.suspended' | translate}}" />
                          <img style="width: 25px" *ngIf="row.status === 'active'"
                              src="assets/images/icons/bullet_green.png" alt="{{'views.user.active' | translate}}"
                              title="{{'views.user.active' | translate}}" />
                            </td>
                          </ng-container>
                    
                        
                          <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header >Name</th>
                            <td mat-cell *matCellDef="let row" (click)="navigatecontractDetail(row)" >{{row.contractId.name}}</td>
                          </ng-container>
                    
                    
                          <ng-container matColumnDef="startDate">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header >
                               Start Date
                              </th>
                              <td mat-cell *matCellDef="let row" (click)="navigatecontractDetail(row)" >{{row.contractId.startDate | date:"dd/MM/yyyy"}}</td>
                            </ng-container>
                            <ng-container matColumnDef="endDate">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                End Date
                              </th>
                              <td mat-cell *matCellDef="let row" (click)="navigatecontractDetail(row)" >{{row.contractId.endDate | date:"dd/MM/yyyy"}}</td>
                            </ng-container>
                            <ng-container matColumnDef="action">
                              <th mat-header-cell *matHeaderCellDef >
                               
                             
                              </th>
                              <td mat-cell *matCellDef="let row">
                         
                                  <app-confirm-delete (onconfirm)="deleteContract(row)"></app-confirm-delete>
              
                              </td>
                            </ng-container>
            <ng-container matColumnDef="filter-status">
              <th mat-header-cell *matHeaderCellDef> 
               
                 </th>
            </ng-container>
          
            <ng-container matColumnDef="filter-name">
              <th mat-header-cell *matHeaderCellDef> 
                 <mat-form-field  class="namest" appearance="outline">
                <input
                  matInput
                  (keyup)="applyFilter('name',$event.target.value)"
                  
                />
              </mat-form-field> </th>
            </ng-container>
             
          
            <ng-container matColumnDef="filter-startDtae">
              <th mat-header-cell *matHeaderCellDef> 
                <mat-form-field class="startdate" appearance="outline">
                <input
                  matInput
                  (keyup)="applyFilter('startDate',$event.target.value)"
                  
                />
              </mat-form-field> </th>
            </ng-container>
            <ng-container matColumnDef="filter-endDate">
              <th mat-header-cell *matHeaderCellDef> 
                  <mat-form-field class="enddate" appearance="outline">
                <input
                  matInput
                  (keyup)="applyFilter('endDate',$event.target.value)"
                  
                />
              </mat-form-field> </th>
            </ng-container>
                    
                          <tr mat-header-row *matHeaderRowDef="contractdisplayedColumns"></tr>
                          <tr mat-header-row *matHeaderRowDef="['filter-status', 'filter-name','filter-startDtae','filter-endDate']"
                class="example-second-header-row">
            </tr>
          
                          <tr mat-row *matRowDef="let row; columns: contractdisplayedColumns;"></tr>
                        </table>
                      </div>
                    
                      <mat-paginator #paginatorcontract="matPaginator"  [pageSizeOptions]="[5, 10, 25, 100]" [length]="contractcount" [pageSize]="10"></mat-paginator>
                        </accordion-group>
                        </div>
                        !-->
                        <div id="roleassignments" *ngIf="authorizedRoleAssignments()" >
                            <accordion-group is-open="false" is-disabled="false">
                                <div accordion-heading  style="width: 100%;">  
                                    <div style="width: 50px; float: left">                    
                                    <img src="assets/images/homepage/organizations.png" alt="{{'views.user.Organization membership' | translate}}" width="30px"/>
                                   </div> {{'views.organization.Role assignments' | translate }} ({{roleassignmentcount}})
                                </div>
                           
                            <span class="" *ngIf="roleassignmentcount < 1">
                                {{'views.organization.The organization is not linked to any role assignment.' | translate }}<br />
                                <br />
                            </span>
                            <div class="example-table-container">
                          
                                <table #sortorgrolesassign mat-table [dataSource]="rolefilteredtabledata" class="example-table"  matSort matSortActive="role"
                                matSortDisableClear matSortDirection="asc" (matSortChange)="resetPaging()">
                                
                            
                                  <!-- Title Column -->
                                  <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef>{{'views.user.Status'|translate}}</th>
                                    <td mat-cell *matCellDef="let row">
                                      <img style="width: 25px" *ngIf="row.accountStatus === 'suspended'"
                                      src="assets/images/icons/bullet_red.png" alt="{{'views.user.suspended' | translate}}"
                                      title="{{'views.user.suspended' | translate}}" />
                                  <img style="width: 25px" *ngIf="row.accountStatus === 'active'"
                                      src="assets/images/icons/bullet_green.png" alt="{{'views.user.active' | translate}}"
                                      title="{{'views.user.active' | translate}}" />
                                    </td>
                                  </ng-container>
                            
                                
                                
                            
                            
                                  <ng-container matColumnDef="user">
                                      <th mat-header-cell *matHeaderCellDef mat-sort-header >
                                       User
                                      </th>
                                      <td mat-cell *matCellDef="let row" (click)="navigateuserdetail(row)" >{{row.mail}}</td>
                                    </ng-container>
                                   
                                    <ng-container matColumnDef="action">
                                      <th mat-header-cell *matHeaderCellDef >
                                       
                                      <!-- <div *ngIf="row.userId.status  === 'suspended'">
                                          <div confirm-activate onconfirm="activateOrganizationUser(organizationuser)"></div>
                                          <div confirm-delete onconfirm="deleteOrganizationUser(organizationuser)"></div>
                                      </div> -->
                                      </th>
                                      <td mat-cell *matCellDef="let row">
                                      
                                        <div >
                                        
                                          <app-confirm-delete (onconfirm)="deleteadminRole(row)"></app-confirm-delete>
                                        </div>
                                      </td>
                                    </ng-container>
                                     <!-- Item Description Column -->
                    <ng-container matColumnDef="filter-status">
                      <th mat-header-cell *matHeaderCellDef> 
                       
                         </th>
                    </ng-container>
                  
                    <!-- Cost Description Column -->
                  
                     <!-- Item Description Column -->
                     
                  
                    <!-- Cost Description Column -->
                    <ng-container matColumnDef="filter-email">
                      <th mat-header-cell *matHeaderCellDef> 
                        <mat-form-field class="mail" appearance="outline">
                        <input
                          matInput
                          (keydown)="applyroleFilter('mail',$event.target.value)"
                          
                        />
                      </mat-form-field> </th>
                    </ng-container>
                  
                            
                                  <tr mat-header-row *matHeaderRowDef="roledisplayedColumns"></tr>
                                  <tr mat-header-row *matHeaderRowDef="['filter-status','filter-email']"
                        class="example-second-header-row">
                    </tr>
                  
                                  <tr mat-row *matRowDef="let row; columns: roledisplayedColumns;"></tr>
                                </table>
                              </div>
                            
                              <mat-paginator  #paginatorroles="matPaginator" [pageSizeOptions]="[5, 10, 25, 100]" [length]="roleassignmentcount" [pageSize]="10"></mat-paginator>
                                </accordion-group>
                                </div>
                                <div id="users"  >
                                  <accordion-group is-open="false" is-disabled="false">
                                      <div accordion-heading  style="width: 100%;">  
                                          <div style="width: 50px; float: left">                    
                                          <img src="assets/images/homepage/organizations.png" alt="{{'views.user.Organization membership' | translate}}" width="30px"/>
                                         </div> {{'views.organization.Users' | translate }} ({{resultsLength}})
                                      </div>
                                 
                                  <span class="" *ngIf="resultsLength < 1">
                                    {{'views.organization.The organization is not linked to any user.' | translate }} <br />
                                    <br />
                                  </span>
                                  <div class="example-table-container">
                                
                                    <table mat-table [dataSource]="filteredAndPagedIssues" class="example-table" matSort
                                    matSortActive="lastname" matSortDisableClear matSortDirection="asc"
                                    (matSortChange)="resetPaging()">
                               <!-- Title Column -->
                               <ng-container matColumnDef="status">
                                 <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="font-weight: bold; color: black;">{{'views.user.Status'|translate}}</th>
                                 <td mat-cell *matCellDef="let row">
                                   <img style="width: 25px" *ngIf="row?.accountStatus === 'suspended'"
                                   src="assets/images/icons/bullet_red.png" alt="{{'views.user.suspended' | translate}}"
                                   title="{{'views.user.suspended' | translate}}" />
                               <img style="width: 25px" *ngIf="row?.accountStatus === 'active'"
                                   src="assets/images/icons/bullet_green.png" alt="{{'views.user.active' | translate}}"
                                   title="{{'views.user.active' | translate}}" />
                                 </td>
                               </ng-container>
                               <ng-container matColumnDef="lastname" style="width: fit-content;">
                                 <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="font-weight: bold; color: black;"><div style="margin:0 -47px">{{'views.user.Last name'|translate}}</div></th>
                                 <td mat-cell *matCellDef="let row" (click)="navigateUserDetail(row)"><div style="margin:0 -40px;width:98px">{{row.sn}}</div></td>
                               </ng-container>
                         
                              
                               <ng-container matColumnDef="firstname" style="width: fit-content;">
                                 <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="font-weight: bold; color: black;">
                                   <div style="margin:0 -67px">  {{'views.user.First name'|translate}}</div></th>
                                 <td mat-cell *matCellDef="let row" (click)="navigateUserDetail(row)"><div style="margin:0 -75px;width: 98px;">{{row.givenName}}</div></td>
                               </ng-container>
                               <ng-container matColumnDef="email" style="width: fit-content;">
                                   <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="font-weight: bold; color: black;" >
                                     {{'views.user.Email'|translate}}
                                   </th>
                                   <td mat-cell *matCellDef="let row" (click)="navigateUserDetail(row)">
                                 
                                     <div style="margin:0 -110px;width:245px;">{{row.mail}}</div></td>
                                 </ng-container>
                                 <ng-container matColumnDef="action" style="width: flex;">
                                   <th mat-header-cell *matHeaderCellDef>
                                      
                                   <!-- <div *ngIf="row.userId.status  === 'suspended'">
                                       <div confirm-activate onconfirm="activateOrganizationUser(organizationuser)"></div>
                                       <div confirm-delete onconfirm="deleteOrganizationUser(organizationuser)"></div>
                                   </div> -->
                                   </th>
                                   <td mat-cell *matCellDef="let row">
                                     <div *ngIf="row.accountStatus  !== 'suspended'" style="    color: #333;
                                     font-weight: 400;
                                     margin: 1px;">
                                       <app-confirmsuspend (onconfirm)="suspendOrganizationUser(row)"></app-confirmsuspend>
                                      <app-confirm-delete (onconfirm)="deleteOrganizationUser(row)"></app-confirm-delete>
                             
                                    </div> 
                                     <div *ngIf="row.accountStatus  === 'suspended'" style="display: flex;color: #333;
                                     font-weight: 400;
                                     margin: 1px;">
                                       <app-confirm-activate (onconfirm)="activateOrganizationUser(row)"></app-confirm-activate>
                                       <app-confirm-delete (onconfirm)="deleteOrganizationUser(row)"></app-confirm-delete>
                                     </div>
                                   </td>
                                 </ng-container>
                                  <!-- Item Description Column -->
                 <ng-container matColumnDef="filter-status">
                   <th mat-header-cell *matHeaderCellDef> 
                    
                      </th>
                 </ng-container>
               
                 <!-- Cost Description Column -->
                 <ng-container matColumnDef="filter-lastname">
                   <th mat-header-cell *matHeaderCellDef> 
                      <mat-form-field appearance="outline" style="margin:0 -69px">
                     <input
                       matInput
                       [formControl]="searchLastNameControl"        
                     />
                   </mat-form-field> </th>
                 </ng-container>
                  <!-- Item Description Column -->
                  <ng-container matColumnDef="filter-firstname">
                   <th mat-header-cell *matHeaderCellDef>
                     <mat-form-field appearance="outline" style="margin:0 -85px">
                       <input
                         matInput
                         [formControl]="searchFirstNameControl"
               
                        
                       />
                     </mat-form-field>
                   </th>
                 </ng-container>
               
                 <!-- Cost Description Column -->
                 <ng-container matColumnDef="filter-email">
                   <th mat-header-cell *matHeaderCellDef> <mat-form-field appearance="outline" style="
                     margin: 0 -111px;
                     width: 263px !important;
                 ">
                     <input
                       matInput
                       [formControl]="searchEmailControl"
                       
                       
                     />
                   </mat-form-field> </th>
                 </ng-container>
                         
                               <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                               <tr mat-header-row *matHeaderRowDef="['filter-status', 'filter-lastname','filter-firstname','filter-email']"
                     class="example-second-header-row">
                 </tr>
               
                               <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                             </table>
                         </div>
                           <mat-paginator #paginatorusers="matPaginator" [pageSizeOptions]="[5, 10, 25, 100]" [length]="resultsLength"  (page)="getPageDetails($event)" [pageSize]="10"></mat-paginator>
                           
                                  </accordion-group>
                                      </div>
                                      <div id="services" >
                                        <accordion-group is-open="false" is-disabled="false">
                                            <div accordion-heading  style="width: 100%;">  
                                                <div style="width: 50px; float: left">                    
                                                <img src="assets/images/homepage/organizations.png" alt="{{'views.user.Organization membership' | translate}}" width="30px"/>
                                               </div>{{'views.contract-detail.Services'| translate}} ({{servicecount}})
                                            </div>
                                       
                                        <span class="" *ngIf="servicecount < 1">
                                            {{'views.contract-detail.The contract is not linked to any organization.'| translate}} <br />
                                        </span>
                                        <div >
                                            <table #sortservices mat-table [dataSource]="servicedataSource" matSort  matSort matSortActive="name"
                                            matSortDisableClear matSortDirection="asc" (matSortChange)="resetPaging()"> 
                    
                                                <!-- ID Column -->
                                                <ng-container matColumnDef="status">
                                                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                                                  <td mat-cell *matCellDef="let row"> 
                                                    <img style="width: 25px" *ngIf="row.status == 'suspended'" src="assets/images/icons/bullet_red.png" alt="{{'views.user.suspended' | translate}}" title="{{'views.user.suspended' | translate}}"/>
                                                    <img style="width: 25px" *ngIf="row.status == 'active'" src="assets/images/icons/bullet_green.png" alt="{{'views.user.active' | translate}}" title="{{'views.user.active' | translate}}"/>
                                                  </td>
                                                </ng-container>
                                            
                                                <!-- Progress Column -->
                                                <ng-container matColumnDef="name">
                                                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Name</th>
                                                  <td mat-cell *matCellDef="let row" (click)="navigateServiceDetail(row)"> {{row.name}} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="action">
                                                    <th mat-header-cell *matHeaderCellDef>
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                            <div >
                                                             <app-confirm-delete (onconfirm)="deleteService(row)"></app-confirm-delete>
                                                           </div> 
                                                           </td>
                                                        
                                                </ng-container>
                                                <ng-container matColumnDef="filter-status">
                                                    <th mat-header-cell *matHeaderCellDef> 
                                                     
                                                       </th>
                                                  </ng-container>
                                                
                            
                                                  <ng-container matColumnDef="filter-name">
                                                    <th mat-header-cell *matHeaderCellDef> 
                                                       <mat-form-field appearance="outline">
                                                      <input
                                                        matInput
                                                        (keyup)="servicefilterData('name',$event.target.value)"
                                                      />
                                                    </mat-form-field> </th>
                                                  </ng-container>
                                                
                                
                                            
                                                <tr mat-header-row *matHeaderRowDef="serviceDisplayedColumns"></tr>
                                                <tr mat-header-row *matHeaderRowDef="['filter-status', 'filter-name']"></tr>
                                                <tr mat-row *matRowDef="let row; columns: serviceDisplayedColumns;"></tr>
                                            
                                              </table>
                                                      <mat-paginator   #paginatorservices="matPaginator" [pageSizeOptions]="[5, 10, 25, 100]" [length]="servicecount" [pageSize]="10"></mat-paginator>
                    
                                              <mat-form-field class="my-form-field" appearance="outline">
                                                <input  class="my-input" [formControl]="serviceformControl"
                                                    placeholder="Add Service " type="text" matInput [matAutocomplete]="auto2">
                                                <mat-autocomplete class="my-autocomplete" #auto2="matAutocomplete" (optionSelected)="updateInputService($event)"
                                                [displayWith]="displayFn">
                                                    <mat-option *ngFor="let item of filteredOptions | async" [value]="item">
                                                        {{item.name}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                            <button class="btn btn-default" (click)="addServices()">
                                                <span class="glyphicon glyphicon-plus" style="margin-right: 10px">
                                                </span>{{"views.home.Add service"| translate}}
                                            </button>
                                   
                                        </div>
                                            </accordion-group>
                                            </div>
                        </accordion>
                        <app-terms-conditions></app-terms-conditions>

</div>