import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { PermissionService } from 'src/app/permission.service';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs-compat';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-userhistory',
  templateUrl: './userhistory.component.html',
  styleUrls: ['./userhistory.component.css']
})
export class UserhistoryComponent implements OnInit , AfterViewInit {
  id: number;
  user ;
  historydisplayedColumns: string[] = ['email', 'createdOn', 'modifiedOn', 'modifiedBy', 'changedField', 'initialValue', 'changedValue'];
  orgdisplayedColumns: string[] = ['organization', 'createdOn', 'modifiedOn', 'modifiedBy', 'changedField', 'initialValue', 'changedValue'];
  roledisplayedColumns: string[] = ['role', 'createdOn', 'modifiedOn', 'modifiedBy', 'changedField', 'initialValue', 'changedValue'];
  isLoadingResults = true;
  isRateLimitReached = false;
  filteredAndPagedIssues: Observable< [] >;
  userHistorydataSource: MatTableDataSource<any>;
  orgHistorydataSource: MatTableDataSource<any>;

  roleuserHistorydataSource: MatTableDataSource<any>;
  filter = {};
  @ViewChild('paginatorroles') paginatorroles: MatPaginator;
  @ViewChild('paginatoruser') paginatoruser: MatPaginator;
  @ViewChild('paginatororg') paginatororg: MatPaginator;  @ViewChild(MatSort) sort: MatSort;
  userrequestForm: FormGroup = new FormGroup({
    userType: new FormControl('external', Validators.required),
    givenName: new FormControl('', [Validators.required, Validators.pattern(/^[^>+\:\;|\/*^$%@\(\)\{\}\[\]!\\~=#*]+$/), ]),
    sn: new FormControl('', [Validators.required, Validators.pattern(/^[^>+\:\;|\/*^$%@\(\)\{\}\[\]!\\~=#*]+$/), ]),
    mainLanguage: new FormControl('', Validators.required),
    mail: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9À-힣.!#$%&'*/=?^_`{|}~-]+@[a-zA-Z0-9À-힣](?:[a-zA-Z0-9À-힣-]{0,61}[a-zA-Z0-9À-힣])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/), ]),
    telephoneNumber: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[0-9]+$/)
    ]),
  });
  uniqueValue ;
  userHistoryData;
  historycount;
  organizationhistorycount;
  userroleshistorycount;
  orgHistoryData;
  roleHistoryData;
  constructor(private router: Router , private permission: PermissionService ,  private route: ActivatedRoute , private commonService: CommonService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.commonService.userDetails(this.id).subscribe(data =>
      {
        this.user = data;
        this.user = data;
        if(this.user['preferences']==null||this.user['preferences'].preferredLanguage =='' ||this.user['preferences'].preferredLanguage === null )
          this.userrequestForm.get('mainLanguage').setValue("EN");
        else
        this.userrequestForm.get('mainLanguage').setValue((this.user['preferences'].preferredLanguage).toUpperCase());
             
      });
  }
  ngAfterViewInit() {
    const userID =this.user._id;
    let userData=[];
    let rolesData=[];
    let orgData=[];
    this.commonService.userHistoryDetails(userID).subscribe(data =>{
      const resultresp=data.result;
      if(resultresp.length>0)
      {
        resultresp.forEach(dataresp =>{
          if(dataresp.operationType=="UserUpdate")
          {
            if (dataresp.changedField && dataresp.changedField.length) {
              for (let i = 0; i < dataresp.changedField.length; i++) {
                userData.push({
                  email: dataresp.mail,
                  createdOn: dataresp.createdOn,
                  modifiedOn: dataresp.modifiedOn,
                  modifiedBy: dataresp.modifiedBy,
                  changedField: dataresp.changedField[i],
                  initialValue: dataresp.initialValue[i] || null, // Handle null or undefined
                  changedValue: dataresp.changedValue[i] || null
                });
              }
            }
          }
          else  if(dataresp.operationType=="OrganizationUpdate")
            {
              if (dataresp.changedField && dataresp.changedField.length) {
                for (let i = 0; i < dataresp.changedField.length; i++) {
                  orgData.push({
                    organization: dataresp.organization,
                    createdOn: dataresp.createdOn,
                    modifiedOn: dataresp.modifiedOn,
                    modifiedBy: dataresp.modifiedBy,
                    changedField: dataresp.changedField[i],
                    initialValue: dataresp.initialValue[i] || null, // Handle null or undefined
                    changedValue: dataresp.changedValue[i] || null
                  });
                }
              }
            }
            else  if(dataresp.operationType=="RoleUpdate")
              {
                if (dataresp.changedField && dataresp.changedField.length) {
                  for (let i = 0; i < dataresp.changedField.length; i++) {
                    rolesData.push({
                      role: dataresp.role,
                      createdOn: dataresp.createdOn,
                      modifiedOn: dataresp.modifiedOn,
                      modifiedBy: dataresp.modifiedBy,
                      changedField: dataresp.changedField[i],
                      initialValue: dataresp.initialValue[i] || null, // Handle null or undefined
                      changedValue: dataresp.changedValue[i] || null
                    });
                  }
                }
              }
        });
        this.userHistoryData= userData.sort((a, b) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime());
         this.historycount = this.userHistoryData.length;
          this.userHistorydataSource = new MatTableDataSource(this.userHistoryData);
          this.userHistorydataSource.paginator = this.paginatoruser;
          this.userHistorydataSource.sort = this.sort;
          this.orgHistoryData= orgData.sort((a, b) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime());
          this.organizationhistorycount = this.orgHistoryData.length;
          this.orgHistorydataSource = new MatTableDataSource(this.orgHistoryData);
          this.orgHistorydataSource.paginator = this.paginatororg;
          this.orgHistorydataSource.sort = this.sort;
          this.roleHistoryData= rolesData.sort((a, b) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime());
          this.userroleshistorycount = this.roleHistoryData.length;
     this.roleuserHistorydataSource = new MatTableDataSource(this.roleHistoryData);
     this.roleuserHistorydataSource.paginator = this.paginatorroles;
     this.roleuserHistorydataSource.sort = this.sort;
      }
      else
      {

      }
    })
    
  }
authorized() {

    return this.permission.isAdmin || this.permission.isHelpDeskAdmin || this.permission.isOrganizationAdmin;
  }
  gotoUserListPage()
  {
    if (this.commonService.previoususerlistlocation )
    {
      this.router.navigate([ this.commonService.previoususerlistlocation ])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
    }
    else
    {
      this.router.navigate(['/user/list'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });

    }
  }

}
